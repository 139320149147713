import { Fragment, useState } from "react"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useDebouncedCallback } from "use-debounce"

import { Maybe } from "src/utils/tsUtil"

const DEFAULT_DELAY = 500 // ms

export interface IMSelectResult {
  name: string
  id: string
}

export function MSelect<Item extends IMSelectResult>({
  label,
  value,
  options = [],
  onSelected,
  onSearch,
  loading,
  renderOption,
  getOptionDisabled,
  debounceDelay = DEFAULT_DELAY,
  required = false,
  disabled,
}: {
  label?: string
  value: Maybe<Item>
  options: Item[]
  onSelected: (i: Maybe<Item>) => void
  onSearch: (s: string) => void
  loading: boolean
  renderOption?: (option: Maybe<Item>, state?: object) => React.ReactNode
  getOptionDisabled?: (option: Maybe<Item>) => boolean
  debounceDelay?: number
  required?: boolean
  disabled?: boolean
}) {
  const [open, setOpen] = useState(false)

  // N.B: Ideally, we should show a loading indicator also when the request is
  // being debounced, as the state of whether there are available items will
  // appear flaky to the user otherwise.
  // Unfortunately, useDebouncedCallback().isPending() seems to often get
  // stuck in a loading state for some reason, so we should probably replace it
  // with something better down the line. For now, however, we can only reliably
  // depend on the loading state of the passed in prop.
  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearch(search),
    debounceDelay,
    { leading: true }
  )

  function handleInputChange(newInputValue: string) {
    const newItems = options.filter((o) => o.name === newInputValue)
    if (newItems.length === 1) {
      onSelected(newItems[0])
    } else {
      onSelected(null)
    }
    debouncedSearch(newInputValue)
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={(option) => option.name || ""}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "input") {
          handleInputChange(newInputValue)
        }
      }}
      onChange={(event, value, reason) => {
        onSelected(value)
      }}
      value={value}
      options={options}
      loading={loading}
      renderOption={renderOption}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          variant="outlined"
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  )
}
